body {
  font-family: 'JetBrains Mono', monospace, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: var(--ring-font-size);
  margin: 0;
  overflow-y: scroll;
}

.App {
  padding: calc(2 * var(--ring-unit));
  display: flex;
  flex-direction: column;
  background-color: var(--ring-content-background-color);
  color: var(--ring-text-color);
}

.fold__top {
  height: 34px !important;
}

.component {
  margin: var(--ring-unit);
}

.cell {
  margin-top: 3px;
  margin-bottom: 3px;
}

.details {
  margin-top: 0;
  margin-bottom: 6px;

  white-space: normal;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.hasUpdates {
  position: relative;
  z-index: 5;

  &::after {
    position: absolute;
    top: calc(0% - 1px);
    right: calc(0%);

    display: block;

    width: var(--ring-unit);
    height: var(--ring-unit);

    content: "";

    border: 1px solid var(--ring-white-text-color);
    border-radius: 50%;
    background-color: var(--ring-icon-success-color);
  }
}

.popup {
  padding: var(--ring-unit);
}

.panel-left {
  box-shadow: 5px 5px 5px lightgray;
  background: #efefef;
  border: 2px solid gray;
  border-radius: 0.5em;
}

.panel-right {
  box-shadow: -5px 5px 5px lightgray;
  background: #efefef;
  border: 2px solid gray;
  border-radius: 0.5em;
}

.report-fullname-column {
  width: 15%;
  min-width: 127px;
}

.type-column {
  width: 15%;
  min-width: 127px;
}

.status-column {
  width: 15%;
  min-width: 80px;
}

.repo-column {
  width: 15%;
  min-width: 105px;
}

.log-column {
  width: 15%;
  min-width: 65px;
}

.date-column {
  width: 20%;
  min-width: 165px;
}

.header {
  text-align: center;
}

.highlight-me {
  padding-left: 15px;
}

.highlight-me:hover {
  background-color: var(--ring-selected-background-color);
}

.footer {
  margin: auto;
  width: 70%;
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

a[aria-expanded=true] .bi-plus-circle-fill {
  display: none
}

a[aria-expanded=true] .bi-dash-circle-fill {
  display: inline
}

a[aria-expanded=false] .bi-dash-circle-fill {
  display: none
}

a[aria-expanded=false] .bi-plus-circle-fill {
  display: inline
}

/* width */
#bfviz > * ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#bfviz > * ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #cdcdcd;
  border-radius: 10px;
}

/* Handle */
#bfviz > * ::-webkit-scrollbar-thumb {
  background: #7d7d7d;
  border-radius: 10px;
}

/* Handle on hover */
#bfviz > * ::-webkit-scrollbar-thumb:hover {
  background: #fe2857;
}

td {
  white-space: normal !important;
  word-wrap: break-word;
  font-size: small;
}

.horizontal-slider {
  display: inline-block;
  color: black;
  text-align: center;
  vertical-align: text-top;
  align-items: start;
  padding: 1em;
}

.slider-track {
  background-color: black;
  height: 0.25rem;
  border-radius: 1em 1em 1em 1em;
  /* border: 1px solid black; */
}

.slider-thumb {
  background-color: light;
  border: 2px solid gray;
  border-radius: 100%;
  width: 1.4rem;
  height: 1.4rem;
  font-size: small;
  bottom: 0.3em;
  cursor: grab
}

.slider-thumb:active {
  box-shadow: inset 5px 5px 10px 20px gainsboro;
  cursor: grabbing;
}

.slider-marks {
  background-color: black;
  border: 2px solid gainsboro;
  border-radius: 100%;
  height: 0.25rem;
  width: 0.25rem;
}

.color-legend-box {
  border: 2px solid darkslategray;
  border-radius: 5%;
}

.color-legend-box:hover {
  border: 4px solid gray;
  border-radius: 20%;
}

.≈:active {
  border: 4px solid gray;
  border-radius: 20%;
}

.coupling-page {
  padding: 32px 16px;
  width: 95%;
  margin: auto;
}

.coupling-page * {
  font-family: var(--ring-font-family), serif
}

.coupling-top-panel {
  margin-bottom: var(--ring-unit);
}

.coupling-top-panel > *:nth-child(2) {
  margin-top: -12px;
}

.coupling-top-panel__filter {
  margin-right: calc(var(--ring-unit) * 3);
}

.coupling-top-panel__description {
  margin-bottom: var(--ring-unit);
}

.coupling-table {
  width: 100%;
}

.coupling-table__header_end {
  direction: rtl;
}

.coupling-table__header_end > * {
  margin-right: var(--ring-unit);
}

.coupling-table__item_rowed {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
}

.coupling-table__item_rowed_centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.coupling-table__item-chevron > * {
  margin: 0;
  cursor: pointer;
}

.coupling-table__item_columned {
  display: flex;
  justify-items: center;
  flex-direction: column;
}

.coupling-table__item_columned > * > * {
  font-family: 'JetBrains Mono', monospace, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: var(--ring-font-size);
}

.coupling-table__header {
  padding-bottom: 0;
  margin-top: 0;
  border-top: none;
}

.coupling-table__row {
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: calc(var(--ring-unit) * 2);
}

.coupling-table__row > *:not(:first-child) {
  padding-left: 16px;
  overflow: visible;
  background-color: var(--bs-gray-100);
}

.coupling-table__collapsed-dependency-schema {
  padding-left: 8px;
  width: 100%;
}

.coupling-table__collapsed-conclusion_main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.coupling-table__collapsed-insight-icon {
  margin-right: 4px;
  color: var(--bs-blue);
}

.coupling-table__collapsed-conclusion-icon {
  margin-right: 4px;
  color: var(--bs-blue);
}

.coupling-table__badge {
  margin-right: calc(var(--ring-unit) / 2);
  font-size: var(--ring-font-size);
}

.tree-dependency-schema {
  overflow-x: scroll;
  white-space: nowrap;
}

.tree-dependency-schema__common-part {
  border-bottom: solid 1px var(--ring-borders-color);
  background: var(--bs-gray-200)
}

.tree-dependency-schema__main {
  display: flex;
  justify-content: flex-start;
  margin-top: var(--ring-unit);
}

.tree-dependency-schema__sub-main {
  margin-left: 36px;
}

.tree-dependency-schema__top-to-bottom-area {
  display: flex;
  justify-content: flex-start;
}

.tree-dependency-schema__bottom-to-top-area {
  display: flex;
  justify-content: space-between;
}

.tree-dependency-schema__numbers {
  margin: auto 0 auto 0;
}

.tree-dependency-schema * {
  font-family: 'JetBrains Mono', monospace, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
